import React, { useState, useEffect }  from 'react';
import SystemCommandArguments from 'components/system_commands/SystemCommandArguments.jsx';

const SystemCommand = ( { config, command_key } ) => {

  return (
    <div>

      <h3> {t(`system_commands.command_${command_key}`)} </h3>

      <SystemCommandArguments command_key={command_key} config={config[command_key]} />

    </div>
  )

}

export default SystemCommand;

