import React, { useState, useEffect }  from 'react';
import styles from 'components/system_commands/_style.module.scss';
import SystemCommand from 'components/system_commands/SystemCommand.jsx';

const ShowPage = ( { config } ) => {

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
          <button className="button button-colored"onclick={() => {}}>{t(`system_commands.command_set_drawing_tag`)} </button>
      </div>

      <div className={styles.main}>
         <SystemCommand command_key="set_drawing_tag" config={config} />
      </div>

    </div>
  )
}

export default ShowPage;
