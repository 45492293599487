import React, { useState, useContext, useEffect, createRef, useRef } from 'react';
import QuoteBuilderList from 'components/quote_builder/QuoteBuilderList.jsx';
import { PostRequest, GetRequest, Tooltip } from 'components/common';
import { HiddenFormField, SelectFormField, LengthFormField, StringFormField, NumberFormField, DelayedNumberFormField, DelayedNumberWithUnitFormField, CurrencyFormField, CurrencyExchange, PriceFormField, UserUnitPrice, UserUnitPriceAttributeField, DescriptionLong, LayoutPartGroupsWindows, LayoutPartGroupsWindowsHidden, TextAreaFormField } from 'components/quote_builder/form_fields';
import { Form, Button } from 'semantic-ui-react';
import { useForm  } from "react-hook-form";
import hash from "object-hash";

const QuoteBuilderForm = ({ config, disabled, showingState, linkToSettings, beforeSave, afterSave, onSaveError, afterCancel, editStateId, editedKlass, options, readOnlyValues, section_uuid, item, extra_title, navSection, colIndex, rowIndex, getExtraItems, navSectionColumnType, context, hideDeleteFromList }) => {
  const default_fetch_path = '/quote-builder/user_unit_prices/:id';
  const default_fetch_system_path = '/quote-builder/user_unit_prices_system/:id';
  const default_update_path = '/quote-builder/user_unit_prices/update';
  const default_create_path = '/quote-builder/user_unit_prices/create/:key'

  const USER_UNIT_PRICE_CHANGED_WITH_ITEM_EVENT_KEY = 'quote_builder.user_unit_price_changed_with_item';

  const STATE_LIST = 'list';
  const STATE_NEW  = 'new';
  const STATE_EDIT = 'edit';
  const STATE_COPY_FROM_SYSTEM  = 'copy_from_system';
  const VALUE_NOT_AVAILABLE = 'not-available'
  const VALUE_NEW = "new-attribute";
  const keyFor = (config) => hash(config);
  const v = (key) => { return values[key] }
  const va = (key) => { return values['user_unit_price_attribute.' + key] }

  const is_any_new_value = (list) => { return list.indexOf(VALUE_NEW) > -1 }
  const is_not_new_value = (list) => { return list.indexOf(VALUE_NEW) == -1 }
  const is_not_new_value_va = (list) => { return is_not_new_value(list.map((key) => va(key))) }

  const [submitInProgress, setSubmitInProgress] = useState(false);

  const defaultValueByFormType = (config) => {
    if(config.form_type == 'price' && config.precision == 4) { return '0.0000' }

    return {
      'UserUnitPriceAttribute': '',
      'UserUnitPrice': 'not-available',
      'select': '',
      'hidden': '',
      'length': '0.00',
      'number': '0.00',
      'integer': '0',
      'price': '0.00',
      'price_with_precision_4': '0.0000',
      'currency': window.default_currency
    }[config.form_type]
  };

  const windowNavSection = () => {
    for(let i=0; i < window.nav_sections.length; i++) {
      if(window.nav_sections[i].sort_order == navSection.index) {
        return window.nav_sections[i];
      }
    }

   throw new Error("windowNavSection could not find navSection: " +   navSection.index + ", user_quote_id: " + window.user_quote_id);
  }

  const partType = () => {
    return navSection.part_type;
  }

  const d = (varName) => {
    if(config.dynamic_variables == undefined) { return; }

    for(let i = 0; i < config.dynamic_variables.length; i++ ){
      if(config.dynamic_variables[i].name == varName) {
        return eval(config.dynamic_variables[i].value)
      };
    }
  }

  const item_uuid_and_extra_item = () => {
    //needs to be same with /Users/emre/code/boxes/lib/quote_builder/models/user_quote_nav_column.rb
    return [item.uuid, item.extra_item_reason].join('_')
  }

  const userUnitPriceAttributes = useRef([]);

  const addToUsetUnitPriceAttributes = (name) => {
    if (userUnitPriceAttributes.current == undefined) { userUnitPriceAttributes.current = []; }
    userUnitPriceAttributes.current.push(name);
    userUnitPriceAttributes.current = userUnitPriceAttributes.current.filter(onlyUnique);
  }

  const item_and_name_exist_in_user_quote_section_properties = (name) => {
    return item != undefined && window.user_quote_section_properties != undefined && window.user_quote_section_properties[item_uuid_and_extra_item()] != undefined && window.user_quote_section_properties[item_uuid_and_extra_item()][section_uuid] != undefined && window.user_quote_section_properties[item_uuid_and_extra_item()][section_uuid][name] != undefined
  }

  const initialValues = () => {
    let values = {
      user_quote_id: window.user_quote_id,
      nav_section_column_type: navSectionColumnType,
      section_uuid: section_uuid,
      id: '',
      price_attributes: [],
      user_exchange_rates: {},
    };

    if(window.user_quote != undefined && window.user_quote.layout_part_group_windows.length > 0) {
      for(let i=0; i < window.user_quote.layout_part_group_windows.length; i++) {
       if(window.user_quote.layout_part_group_windows[i].value != undefined) {
         values[window.user_quote.layout_part_group_windows[i].attribute_name] = window.user_quote.layout_part_group_windows[i].value;
       } else {
         values[window.user_quote.layout_part_group_windows[i].attribute_name] = 'not-set';
       }
      }
    }

    if(navSection != undefined) {
      values.nav_section = navSection;
      values.row_index = rowIndex;
      values.item = item;
    }

    config.attributes.map( (config) => {
      if(config.form_type == 'price') {
        values[ 'price_attributes' ] = values[ 'price_attributes' ].concat([config.name]);
      }

      if (item_and_name_exist_in_user_quote_section_properties(config.name) ) {
        values[config.name] = window.user_quote_section_properties[item_uuid_and_extra_item()][section_uuid][config.name];
      } else {
        if (config.default_value != undefined) {
          values[config.name] = config.default_value;
        } else {
          if (config.default_value_eval != undefined) {
            values[config.name] = eval(config.default_value_eval);
          } else {
            if(config.default_value_in_values != undefined) {
              values[config.name] = values[config.default_value_in_values];
            } else {
              values[config.name] = defaultValueByFormType(config);
            }
          }
        }
      }
    });

    return values;
  }

  const [componentShowingState, setComponentShowingState] = useState(showingState == undefined ? (config.list.enabled ? STATE_LIST : STATE_NEW) : showingState);
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [itemIdBeingEdited, setItemIdBeingEdited] = useState(editStateId);
  const [postOnChangeResponse, setPostOnChangeResponse] = useState({});
  const [values, setValues] = useState(initialValues());
  const [inCompleteMessage, setInCompleteMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const outputCurrencies = useRef([]);
  const {register, handleSubmit, clearErrors, trigger, formState: { errors }} = useForm();

  const scrollTop = () => {
    if(window.QuoteBuilderFormScrollTop) {
      $("html, body").animate({ scrollTop: 100 }, "slow");
    }
  }

  const setValue = (id, value) => {
    setValues( (previous) => {
      if (typeof value === 'function') {
        previous[id] = value(previous);
      } else {
        previous[id] = value;
      }
      return { ...previous };
    })
  }

  const getDefaultFetchPath = () => {
    if(editedKlass == "UserUnitPriceAttribute") {
      return `/quote-builder/user_unit_price_attributes/properties/${itemIdBeingEdited}`
    }

    return (config.fetch_path || default_fetch_path).replace(':id', itemIdBeingEdited);
  }

  const getDefaultUpdatePath = () => {
    if(editedKlass == "UserUnitPriceAttribute") {
      return `/quote-builder/user_unit_price_attributes/update_properties/${itemIdBeingEdited}`
    }

    return config.submit.update_path || default_update_path;
  }

  const getFetchEnabled = () => {
    if(editedKlass == "UserUnitPriceAttribute") { return true }
    return config.fetch_enabled;
  }

  useEffect(() => {
    if(disabled) { return };
    if(getFetchEnabled() && itemIdBeingEdited > 0 && componentShowingState == STATE_EDIT) {
      setFetchInProgress(true);

      GetRequest({
        url: getDefaultFetchPath(),
        callback: (data) => {
          data.price_attributes = initialValues()['price_attributes'];
          setFetchInProgress(true);

          for (const [key, value] of Object.entries(data)) {
            setValue(key, value);
          }

          if(data.is_system != undefined && setIsSystem != undefined) {
            setIsSystem(data.is_system);
          }

          scrollTop();

          setFetchInProgress(false);
        }
      })
    }

    if(config.fetch_enabled && itemIdBeingEdited > 0 && componentShowingState == STATE_COPY_FROM_SYSTEM) {
      setFetchInProgress(true);

      GetRequest({
        url: default_fetch_system_path.replace(':id', itemIdBeingEdited),
        callback: (data) => {

          data.price_attributes = initialValues()['price_attributes'];

          setFetchInProgress(true);

          for (const [key, value] of Object.entries(data)) {
            if(config.mark_system.system_attribute_names.includes(key)) {
              setValue(key, value);
            }
          }

          setValue('copied_from_id', itemIdBeingEdited);

          scrollTop();
          setFetchInProgress(false);

          setTimeout(() => {
            setComponentShowingState(() => { return STATE_LIST});
            setTimeout(() => {setComponentShowingState(() => { return STATE_COPY_FROM_SYSTEM});}, 2)
          }, 2)
        }
      })
    }

  }, [ itemIdBeingEdited ]);

  useEffect( () => {
    if(disabled) { return };

    if(componentShowingState == STATE_NEW) {
      if(window.QuoteBuilderFormScrollTop) { scrollTop() }
      setValues( { ...initialValues() } );
    }
  }, [componentShowingState])

  const save = async () => {
    if(disabled) { return };
    setSubmitInProgress( () => true )

    setFetchInProgress(true);

    if(beforeSave != undefined) beforeSave();

    let url;

    switch(componentShowingState) {
      case STATE_NEW:
        url = (config.submit.create_path || default_create_path).replace(':key', config.key)
        break;

      case STATE_COPY_FROM_SYSTEM:
        url = (config.submit.create_path || default_create_path).replace(':key', config.key)
        break;

      case STATE_EDIT:
        url = getDefaultUpdatePath()
        break;

      default:
        throw 'invalid componentShowingState';
    }

    if(await trigger()) {
      PostRequest({
        url: url,
        data: values,
        callback: (data) => {
          setItemIdBeingEdited(null);
          setFetchInProgress(false);
          document.dispatchEvent(new CustomEvent('quote_builder.user_unit_price_saved', {}) );
          if(afterSave != undefined) {
            afterSave(data.id);
          } else {
            setComponentShowingState(STATE_LIST);
          }

         setSubmitInProgress( () => false )
        },
        400: (data) => {
          alert(data.errors);
          setItemIdBeingEdited(null);
          setFetchInProgress(false);
        }
      })
    }
  }

  const cancel = () => {
    clearErrors();
    setItemIdBeingEdited(null);
    setComponentShowingState(STATE_LIST);
    if(afterCancel != undefined) {
      afterCancel();
    }
  }

  const windowValue = () => {
    let key = 'window_type__default';

    if(item.layout_part_group_code != '' && item.layout_part_group_code != undefined && item.layout_part_group_code != null) {
      key = `window_type_${item.layout_part_group_code}`;
    }

    return values[key];
  }

  // requestGetExtraItems
  const requestGetExtraItemsRef = useRef([]);
  const requestGetExtraItems = (key_value) => {
    requestGetExtraItemsRef.current.push([key_value, section_uuid]);
  }

  const processGetExtraItemRequests = () => {
    for(let i = 0; i < requestGetExtraItemsRef.current.length; i++) {
      let args = requestGetExtraItemsRef.current[i];
      getExtraItems(args[0], args[1], values, item, options);
    }

    requestGetExtraItemsRef.current = [];
    if(config.get_extra_items_on_change) {
      getExtraItems({ key: config.get_extra_items_key, value: config.get_extra_items_value }, eval(config.get_extra_items_uuid_eval) , values, item, options);
    }
  }

  //requestPublishItemOnChange
  const requestPublishItemOnChangeRef = useRef([]);
  const requestPublishItemOnChange = (msg) => {
    requestPublishItemOnChangeRef.current.push(msg);
  }

  const processPublishItemOnChange = () => {
    for(let i=0; i < requestPublishItemOnChangeRef.current.length; i++) {
      let message = requestPublishItemOnChangeRef.current[i];
      let publishItemKey = message.publish_item_key != undefined ? message.publish_item_key : USER_UNIT_PRICE_CHANGED_WITH_ITEM_EVENT_KEY;

      message.values = values;
      message.item = item;
      message.nav_section = navSection;

      // console.log({key: publishItemKey, message: message})
      document.dispatchEvent(new CustomEvent(publishItemKey, { detail: message } ));
    }
    requestPublishItemOnChangeRef.current = [];
  }

  const formFieldFactory = (config, index) => {
    let formField;

    switch(config.form_type) {
      case 'UserUnitPriceAttribute':
        formField = <UserUnitPriceAttributeField
            key={keyFor(config)}
            config={config}
            options={options}
            setValue={setValue}
            readOnlyValues={readOnlyValues}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            disabled={disabled}
            register={register}
            errors={errors}
            context={context}
            item={item}
            navSection={navSection}
            requestPublishItemOnChange={requestPublishItemOnChange}
          />
        break;

      case 'UserUnitPrice':
        addToUsetUnitPriceAttributes(config.name);

        formField = <UserUnitPrice
            key={keyFor(config)}
            config={config}
            value={values[config.name]}
            values={values}
            setValue={setValue}
            windowValue={windowValue()}
            options={options}
            item={item}
            navSection={navSection}
            setFetchInProgress={setFetchInProgress}
            disabled={disabled}
            requestGetExtraItems={requestGetExtraItems}
            requestPublishItemOnChange={requestPublishItemOnChange}
            navSectionColumnType={navSectionColumnType}
          />
        break;

      case 'select':
        formField = <SelectFormField
            key={keyFor(config)}
            config={config}
            options={options}
            setValue={setValue}
            item={item}
            navSection={navSection}
            readOnlyValues={readOnlyValues}
            setFetchInProgress={setFetchInProgress}
            requestGetExtraItems={requestGetExtraItems}
            value={values[config.name]}
            values={values}
            requestPublishItemOnChange={requestPublishItemOnChange}
            d={d}
            disabled={disabled}
            register={register}
            errors={errors}
            context={context}
          />
        break;

      case 'hidden':
        formField = <HiddenFormField
            key={keyFor(config)}
            config={config}
            options={options}
            setValue={setValue}
            item={item}
            navSection={navSection}
            setFetchInProgress={setFetchInProgress}
            requestGetExtraItems={requestGetExtraItems}
            value={values[config.name]}
            disabled={disabled}
          />
        break;

      case 'length':
        formField = <LengthFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            unit= {config.length_unit != undefined ? config.length_unit : values[config.length_unit_in_values]}
            disabled={disabled}
          />
        break;

      case 'number':
        formField = <NumberFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'delayed_number':
        formField = <DelayedNumberFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            item={item}
            navSection={navSection}
            setFetchInProgress={setFetchInProgress}
            requestPublishItemOnChange={requestPublishItemOnChange}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'integer':
        formField = <NumberFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            integerOnly={true}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'delayed_integer':
        formField = <DelayedNumberFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            item={item}
            navSection={navSection}
            integerOnly={true}
            setFetchInProgress={setFetchInProgress}
            requestPublishItemOnChange={requestPublishItemOnChange}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'delayed_integer_with_unit':
        formField = <DelayedNumberWithUnitFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            item={item}
            navSection={navSection}
            integerOnly={true}
            setFetchInProgress={setFetchInProgress}
            unit= {config.length_unit != undefined ? config.length_unit : values[config.length_unit_in_values]}
            requestPublishItemOnChange={requestPublishItemOnChange}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'string':
        formField = <StringFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'email':
        formField = <StringFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            email={true}
            disabled={disabled}
          />
        break;

      case 'textArea':
        formField = <TextAreaFormField
            key={keyFor(config)}
            config={config}
            setValue={setValue}
            setFetchInProgress={setFetchInProgress}
            value={values[config.name]}
            register={register}
            errors={errors}
            disabled={disabled}
          />
        break;

      case 'currency':
        formField = <CurrencyFormField
          key={keyFor(config)}
          config={config}
          setValue={setValue}
          setFetchInProgress={setFetchInProgress}
          value={values[config.name]}
          disabled={disabled}
        />
        break;

      case 'CurrencyExchange':
        formField = <CurrencyExchange
          key={config.name}
          config={config}
        />
        break;

      case 'LayoutPartGroupsWindows':
        formField = <LayoutPartGroupsWindows
          key={config.name}
          config={config}
          values={values}
          setValue={setValue}
        />
        break;

      case 'LayoutPartGroupsWindowsHidden':
        formField = <LayoutPartGroupsWindowsHidden
          key={config.name}
          config={config}
          values={values}
          setValue={setValue}
        />
        break;

      case 'price':
        formField = <PriceFormField
          key={config.name}
          config={config}
          setValue={setValue}
          setFetchInProgress={setFetchInProgress}
          currency={values['currency']}
          register={register}
          errors={errors}
          value={values[config.name]}
          values={values}
          disabled={disabled}
          precision={config.precision}
        />
        break;

      case 'price_with_precision_4':
        formField = <PriceFormField
          key={config.name}
          config={config}
          setValue={setValue}
          setFetchInProgress={setFetchInProgress}
          currency={values['currency']}
          register={register}
          errors={errors}
          value={values[config.name]}
          disabled={disabled}
          precision="4"
        />
        break;

        case 'description_long':
        formField = showLongDescriptions && <DescriptionLong
          key={config.name}
          values={values}
          config={config}
          disabled={disabled}
        />
        break;
      }

      if(config.visible_if != undefined) {
        return eval(config.visible_if) && formField
      }

      return formField;
   }

   const myFormRef = useRef();

   const submit = async () => {
    if(await trigger()) {
      myFormRef.current.handleSubmit(save);
    }
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const onPostOnChangeCompletedEnabled = useRef(false);

  const onPostOnChangeCompleted = (data) => {
    document.dispatchEvent(new CustomEvent('quote_builder.section_result_updated', { detail: section_uuid }));

    if(data.user_quote_section_properties != undefined) {
      window.user_quote_section_properties = data.user_quote_section_properties;
    }

    if(data.user_quote != undefined) {
      window.user_quote = data.user_quote;
    }

    if(data.nav_sections != undefined) {
      window.nav_sections = data.nav_sections;
    }
  }

  const anyUserUnitPriceAttributeIsSet = useRef(false);
  const valuesHash = useRef();

  const postOnChange = async () => {
    if(disabled) { return };
    if(valuesHash.current != undefined && hash(values) == valuesHash.current) { return }

    setInCompleteMessage(null);
    setErrorMessage(null);
    setPostOnChangeResponse([]);

    if(anyUserUnitPriceAttributeIsSet.current == false && userUnitPriceAttributes.current != undefined) {
      for(let i = 0; i < userUnitPriceAttributes.current.length; i++) {
        if(values[userUnitPriceAttributes.current[i]] != VALUE_NOT_AVAILABLE) {
          anyUserUnitPriceAttributeIsSet.current = true;
        }
      }
    }
    if(anyUserUnitPriceAttributeIsSet.current == false && userUnitPriceAttributes.current != undefined && userUnitPriceAttributes.current.length > 0) {
      setTimeout(processPublishItemOnChange, 250);
      return;
    }

    if(await trigger()) {
      let valuesToPost = { ...values };
      // if(sectionClearEnabled.current) { valuesToPost.section_clear_enabled = true; }
      valuesHash.current = hash(values);

      PostRequest({
        url: config.post_on_change_path,
        data: valuesToPost,
        callback: (data) => {
          if(data.results != undefined) {
            setPostOnChangeResponse(data.results);
            outputCurrencies.current = data.results.currencies;
          }
          onPostOnChangeCompleted(data);
          onPostOnChangeCompletedEnabled.current = true;
          processPublishItemOnChange();
          processGetExtraItemRequests();
          setFetchInProgress(false);
        },
        422: (data) => {
          setInCompleteMessage(data.message);
          setPostOnChangeResponse({line_item_prices: []});
          if(onPostOnChangeCompletedEnabled.current) { onPostOnChangeCompleted(data); }
          processPublishItemOnChange();
          processGetExtraItemRequests();
          setFetchInProgress(false);
        },
        400: (data) => {
          setErrorMessage(data.message);
          setPostOnChangeResponse({line_item_prices: []});
          if(onPostOnChangeCompletedEnabled.current) { onPostOnChangeCompleted(data); }
          processPublishItemOnChange();
          processGetExtraItemRequests();
          setFetchInProgress(false);
        }
      });
    }
  }

  const validateOnChange = () => {
    if(disabled) { return };
    setInCompleteMessage(null);
    setErrorMessage(null);

    trigger();
  }

  useEffect(() => {
   if(disabled) { return };

   if(config.post_on_change && values['updated_at'] != undefined){
    postOnChange();
   }
  }, [values['updated_at']]);

  const disabledIf = () => {
    if(submitInProgress) { return true }
    return (config.submit.disabled_if != undefined && eval(config.submit.disabled_if))
  }

  useEffect(() => {
    let event = new CustomEvent('quote_builder.output_currencies_changed', { "detail": outputCurrencies.current });
    document.dispatchEvent(event);

  }, [outputCurrencies.current]);

  useEffect(() => {
    if(config.post_on_change) {
      let listener = function(e) {
        setValue('user_exchange_rates', (previous) => {
          previous['user_exchange_rates'][e.detail.key] = e.detail.value;
          return { ...previous['user_exchange_rates'] };
        });

        setValue('updated_at', Date.now());
      };

      document.addEventListener('quote_builder.currency_exchange_rate_changed', listener);
      return () => { document.removeEventListener('quote_builder.currency_exchange_rate_changed', listener);}
    }
  }, []);

  const sectionClearEnabled = useRef(false);

  const onKeyUpWrapped = (e) => {
    // sectionClearEnabled.current = true;
    postOnChange()
  }

  const onBlurWrapped = (e) => {
    // sectionClearEnabled.current = true;
    postOnChange()
  }
  const cookieNameforLongDescription = 'quote_builder_settings_show_long_descriptions';
  const [showLongDescriptions, setShowLongDescriptions] = useState(true);

  const toggleDescriptionsControl = () => {
    return (
      <label className="switch-small" title={t('quote_builder.toggle_summaries')}>
        <input type="checkbox" className="pref" checked={showLongDescriptions} onChange={handleToggleLongDescriptions}/>
        <span className="slider-small"></span>
      </label>);
  }
  const handleToggleLongDescriptions = () => {
    setShowLongDescriptions(!showLongDescriptions);
    setCookie(cookieNameforLongDescription, !showLongDescriptions, 365)
  }

  const titleTr = () => {
    return (config.new_title != undefined || config.edit_title != undefined || config.title != undefined) && (
      <tr>
        <th colSpan="2">
          {config.new_title != undefined && componentShowingState == STATE_NEW && t(config.new_title) }
          {config.new_title != undefined && componentShowingState == STATE_COPY_FROM_SYSTEM && t(config.new_title) }
          {config.edit_title != undefined && componentShowingState == STATE_EDIT && t(config.edit_title)}
          {config.title != undefined && t(config.title) } {false && config.title != undefined && extra_title != undefined && ` (${t(extra_title)})` }
          {config.toggle_long_descriptions && <div className="float-right"> {toggleDescriptionsControl()} </div>}

          {localDisabledReason && (<span className="float-right"> <small className="q-read-only-message"> {localDisabledReason} <br /> </small> </span>)}
        </th>
      </tr>);
  }

  const [localDisabled, setLocalDisabled] = useState(false);
  const [localDisabledReason, setLocalDisabledReason] = useState('');
  if(config.disabled_if != undefined) {
    useEffect(() => {
      if( eval(config.disabled_if) ) {
        setLocalDisabled( (_prev) => true )
        setLocalDisabledReason( (_prev) => t(config.disabled_if_reason) );
      } else {
        setLocalDisabled( (_prev) => false )
        setLocalDisabledReason( (_prev) => null);
      }
    }, [values])
  }

  const [isSystem, setIsSystem] = useState(false);
  const handleIsSystemChange = (e) => {
    PostRequest({
      url: `/quote-builder/user_unit_prices/update-is-system`,
      data: {
        id: itemIdBeingEdited,
        is_system: e.target.checked
      },
      callback: (data) => {
        setIsSystem(() => {return data.is_system})
      }
    })
  }

  const isMarkSystemEnabled = () => {
    return componentShowingState == STATE_EDIT && config.mark_system != undefined && config.mark_system.enabled && window.mark_system_enabled == 'true';
  }

  return (
    <div className={`${config.class_name != undefined ? config.class_name : ''} ${(fetchInProgress || disabled || localDisabled) ? 'disabledForm' : ''}`} data-section-uuid={section_uuid}>
      {config.list.enabled && componentShowingState == STATE_LIST && <QuoteBuilderList config={config} setComponentShowingState={setComponentShowingState} setItemIdBeingEdited={setItemIdBeingEdited} hideDeleteFromList={hideDeleteFromList}/> }

      {(componentShowingState == STATE_NEW || componentShowingState == STATE_EDIT || componentShowingState == STATE_COPY_FROM_SYSTEM) && (
        <Form onSubmit={handleSubmit(save)} ref={myFormRef} onBlur={config.post_on_change ? onBlurWrapped : validateOnChange}>
         <table className="table table-striped table-bordered-outside quote_builder_table">
          <tbody>
            {titleTr()}

            {config.attributes.map(formFieldFactory)}

            {(config.submit.enabled || config.cancel.enabled) &&
              <tr>
                <td colSpan="2">
                  {config.submit.enabled && <button className="btn simple-btn" type='button' disabled={disabledIf()} onClick={submit} >
                    {submitInProgress && t('three_d.please_wait')}
                    {!submitInProgress && componentShowingState == STATE_NEW && t('quote_builder.save') }
                    {!submitInProgress && componentShowingState == STATE_COPY_FROM_SYSTEM && t('quote_builder.save') }
                    {!submitInProgress && componentShowingState == STATE_EDIT && t('quote_builder.update') }
                  </button> }

                  &nbsp;
                  &nbsp;

                  {config.cancel.enabled && <button className="btn" onClick={cancel}> {t('quote_builder.cancel')} </button>}

                  {linkToSettings && <div className="float-right"> <small> <a href={`/quote-builder/settings/${config.key}`} target="_blank"> <i className="fa fa-cog" aria-hidden="true"></i> {t('nav.quote_builder_settings_long')} </a>  </small> </div> }
                </td>
              </tr>}

              {((config.post_on_change && postOnChangeResponse != undefined && postOnChangeResponse.line_item_prices != undefined && postOnChangeResponse.line_item_prices.length > 0) || inCompleteMessage || errorMessage) && (
                <tr>
                  <td></td>
                  <td colSpan="1">
                     {config.post_on_change && postOnChangeResponse.line_item_prices.length > 0 && (
                        <table className="float-right full_width">
                          <tbody>

                          {config.show_number_of_sheets != false && postOnChangeResponse.number_of_sheets != undefined && (
                          <tr key="number_of_sheets">
                            <td>
                              {t('quote_builder.number_of_sheets')}
                              {postOnChangeResponse.num_of_drawings_in_set > 1 && (
                                <Tooltip title={t('quote_builder.num_of_drawings_in_set_description').replace('%s', postOnChangeResponse.num_of_drawings_in_set)} />)}
                            </td>
                            <td className="align-right"> {postOnChangeResponse.number_of_sheets}</td>
                          </tr>
                          ) }

                          { postOnChangeResponse.result_info_rows.map( (resultInfoRow) => {
                            return (<tr key={resultInfoRow.key}>
                              <td>
                                {t(resultInfoRow.key)}
                                {resultInfoRow.tooltip_text != undefined && (
                                  <Tooltip title={resultInfoRow.tooltip_text} />)}
                              </td>
                              <td className="align-right">
                                {resultInfoRow.value != undefined && resultInfoRow.value}
                              </td>
                            </tr>)
                          } )}

                          {postOnChangeResponse.line_item_prices.map((line_item_price) => {
                            return (
                              <tr key={line_item_price.title}>
                                <td> {line_item_price.title} {line_item_price.notes != '' && <Tooltip key={line_item_price.notes} title={line_item_price.notes} />} </td>
                                <td className="align-right">
                                {line_item_price.price_formatted}

                                 {line_item_price.price_in_pence > 0 && line_item_price.exchange_info_presented != '' && (
                                    <Tooltip key={line_item_price.exchange_info_presented} title={line_item_price.exchange_info_presented} />
                                  )}
                                </td>
                              </tr>
                            )
                          })}

                          {config.show_price_for_one && (<>
                            <tr className="tr-bordered-top">
                              <td> {t('quote_builder.price_for_one')} </td>
                              <td className="align-right"> {postOnChangeResponse.price_for_one_formatted}</td>
                            </tr>

                           {config.show_price_for_sheet && (
                            <tr className="">
                              <td> {t('quote_builder.price_for_sheet')} </td>
                              <td className="align-right"> {postOnChangeResponse.price_for_sheet_formatted}</td>
                            </tr>

                            )}
                            <tr className="">
                              <td> {t('quote_builder.total')} </td>
                              <td className="align-right"> {postOnChangeResponse.total_price_formatted}</td>
                            </tr>
                          </>)}

                          {config.show_price_for_one != true && (<>
                             <tr className="tr-bordered-top">
                              <td> {t('quote_builder.total')} </td>
                              <td className="align-right"> {postOnChangeResponse.total_price_formatted}</td>
                            </tr>
                          </>)}
                          </tbody>
                        </table>
                      )}

                  </td>
                </tr>
              )}

            {(isMarkSystemEnabled() && (
              <tr>
                <td colSpan="2">
                  <div className="float-right">
                    <input type="checkbox" className="pref" checked={isSystem} onChange={handleIsSystemChange} /> Kütüphaneye dahil
                    </div>
                </td>
              </tr>
            ))}

          </tbody>
        </table>

        {inCompleteMessage && (<>
        <div className="alert alert-danger fade in">
          <i className="fa fa-exclamation-circle"></i>
            &nbsp;
            <strong>
              <span dangerouslySetInnerHTML={{__html: inCompleteMessage}}></span>
            </strong>
          </div>

        </>)}

        {errorMessage && (<>
          <div className="alert alert-danger fade in">
             <i className="fa fa-exclamation-circle"></i>
             &nbsp;
            <strong>
              <span dangerouslySetInnerHTML={{__html: errorMessage}}></span>
            </strong>
          </div>

        </>)}

      </Form>
      )}

    </div>
  )
}

export default QuoteBuilderForm;
