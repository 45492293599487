import React, { useState, useEffect }  from 'react';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import styles from 'components/system_commands/_style.module.scss';

const SystemCommandArguments = ( { config, command_key } ) => {
  const [result, setResult] = useState("")
  const [error, setError] = useState("")

  const afterSave = ( result ) => {

    if(result.status == "ok") {
      setError( () =>  '')
      setResult( () => result.message );
    }

    if(result.status == "error") {
      setResult( () => '');
      setError(() => "HATA!    " + result.message);
    }
  }

  const beforeSave = () => {
    setError( () => '');
    setResult( () => '....lütfen bekleyiniz...');
  }

  const closeButton = (setter) => {
    return <button className={styles.close_div} onClick={ () => {  setter( () => ''); } } >✖</button>
  }

  return (
    <div>
      <QuoteBuilderForm config={config} afterSave={afterSave} beforeSave={beforeSave} />
      {result != "" && <div className={styles.result_success_div}> {result} {closeButton(setResult)}  </div>}
      {error != "" &&  <div className={styles.result_error_div}> {error}  {closeButton(setError)}   </div>}
    </div>
  )
}

export default SystemCommandArguments;
